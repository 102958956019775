import React, { Component } from 'react';
import {connect} from "react-redux";

import styles from "./CityResultImage.module.css";

import CityProductCanvas from "../Canvas/CityProductCanvas";

const mapStateToProps = (state) => {
    return {
        facts: state.facts
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

class CityResultImage extends Component {

    render() {
        const {facts} = this.props;

        return (
            <div className={styles.cityResultImage} style={{height: (facts['p_profilhoehe'] / 3 + 300) + 'px', marginBottom: - 0.5 * (facts['p_profilhoehe'] / 3 + 200)}}>
                <CityProductCanvas view={false} closePositioning={true} enableButtons={false} hideButtons={false} disableRefetch={false} />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityResultImage);
