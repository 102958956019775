import React, { Component } from 'react';
import {connect} from "react-redux";

import * as ActionTypes from "../../actions";

import Value from "../../components/Value";

import AccessoryGroup from "./AccessoryGroup";
import SnippetContent from "./SnippetContent";

import styles from './ProductName.module.css';

const mapStateToProps = (state) => {
    return {
        facts: state.facts,
        products: state.products
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchProduct: (id) => dispatch(ActionTypes.fetchProduct(id))
    };
};

class ProductName extends Component {

    componentDidMount() {
        const {id} = this.props;
        this.props.fetchProduct(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {id} = this.props;

        if (prevProps.id && prevProps.id !== id) { // ID has changed ...
            this.props.fetchProduct(id);
        }
    }

    showvalueReducer = showvalue => (accumulator, currentValue) => {
        if (currentValue.showvalues.includes(showvalue)) {
            accumulator = currentValue;
        }
        return accumulator;
    };

    showvalueMultipleReducer = showvalue => (accumulator, currentValue) => {
        if (currentValue.showvalues.includes(showvalue)) {
            if( ! accumulator) {
                accumulator = [];
            }
            accumulator.push(currentValue);
        }
        return accumulator;
    }

    getValue(node, showvalue) {
        return node?.values?.reduce(this.showvalueReducer(showvalue), null);
    }

    getMultipleValues(node, showvalue) {
        return node?.values?.reduce(this.showvalueMultipleReducer(showvalue), null);
    }

    handleAddAccessory = (value) => {
        this.props.onAddAccessory(value);
    }

    handleRemoveAccessory = (value) => {
        this.props.onRemoveAccessory(value);
    }

    htmlContent(html) {
        return {__html: html};
    }

    render() {
        const {id, meta, showSpecs, showAccessories, showNotice, selectedAccessories, products, isAccessory} = this.props;
        const product = products[id] ? products[id] : false;

        const name = this.getValue(product, 'pk_matktxt');
        const artNo = this.getValue(product, 'pk_matnr');
        const variant = this.getValue(product, 'pk_variantenbezeichnung');
        const techSpecs = this.getMultipleValues(product, 'pk_datalist');
        const accessories = this.getMultipleValues(product, 'pk_zubehoer');
        const notice = this.getValue(product, 'pk_hinweis_ausland_crve');

        return product && (
            <div>
                <div className={isAccessory ? styles.productHeadingAccessory : styles.productHeading}>
                    <Value value={name} />
                </div>
                <div className={styles.productSubline}>
                    <Value value={artNo} /> <Value value={variant} />
                </div>
                {showSpecs && techSpecs && (
                    <div className={styles.productSpecs}>
                        {techSpecs.map((value, index) => (
                            <span key={index}>
                                {index < 6 && (
                                    <span className={styles.productSpecsItem}>
                                        {value.name.trim()}: <Value key={index} value={value} />
                                    </span>
                                )}
                            </span>
                        ))}
                        {meta && meta.height > 0 && (
                            <span className={styles.productSpecsItem}>
                                <SnippetContent id="p_label_result_height" fallback="Höhe:" /> {meta.height} mm
                            </span>
                        )}
                    </div>
                )}
                {showNotice && notice && (
                    <div className={styles.productNotice}>
                        <div dangerouslySetInnerHTML={this.htmlContent(notice.value.value)}></div>
                    </div>
                )}
                {showAccessories && accessories && (
                    <div className={styles.productAccessories}>
                        <h3 className={styles.productAccessoriesHeading}>
                            <SnippetContent id="p_label_accessories" fallback="Zubehör" />
                        </h3>
                        {accessories.map((value, index) => (
                            <div key={index}>
                                {value.value.map((v, i) => (
                                    <AccessoryGroup id={v.id} selectedAccessories={selectedAccessories} onAddAccessory={this.handleAddAccessory} onRemoveAccessory={this.handleRemoveAccessory} key={i} />
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductName);
