import React, { Component } from 'react';
import {connect} from "react-redux";
import { Link } from "@reach/router";

import * as InquiryService from '../../services/InquiryService';

import styles from "./ResultView.module.css";

import AppInset from "../../components/AppInset";

import ResultImage from "../Result/ResultImage";

import Product from '../Partials/Product';
import ProductName from '../Partials/ProductName';
import SnippetContent from '../Partials/SnippetContent';

const mapStateToProps = (state) => {
    return {
        configuratorId: state.configuratorId,
        facts: state.facts,
        parameterSet: state.parameterSet,
        isLoading: state.isLoading,
        resumePaths: state.resumePaths
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

class ResultView extends Component {

    constructor(props) {
        super(props);

        this.inputName = React.createRef();
        this.inputEmail = React.createRef();
        this.inputCompany = React.createRef();
        this.inputPostalCode = React.createRef();
        this.inputLocality = React.createRef();
        this.inputMessage = React.createRef();
        this.inputAcceptConditions = React.createRef();

        this.state = {
            inquiryMissingMandatoryFields: false,
            inquirySending: false,
            inquirySent: false,
            inquirySuccessful: false
        };
    }

    handlePrint = event => {
        window.print();
    }

    addPositions(categories) {
        const {configuratorId} = this.props;

        return categories.map(category => {
            if (configuratorId === 'city_elements') {
                switch (category.id) {
                    case '01-G':
                        category.pos = 3;
                        break;
                    case '02-A':
                        category.pos = 1;
                        break;
                    case '03-Z':
                        category.pos = 2;
                        break;
                    case '04-ACC':
                        category.pos = 100;
                        break;
                    default:
                        category.pos = 0;
                }
            }

            return category;
        })
    }

    handleInquiry = event => {
        const name = this.inputName.value;
        const email = this.inputEmail.value;
        const company = this.inputCompany.value;
        const postalCode = this.inputPostalCode.value;
        const locality = this.inputLocality.value;
        const message = this.inputMessage.value;
        const acceptConditions = this.inputAcceptConditions.checked;

        if (name && email && message && acceptConditions) {
            this.setState({...this.state, inquiryMissingMandatoryFields: false, inquirySending: true});

            InquiryService.inquireConfiguration(name, email, company, postalCode, locality, message, this.props.facts, this.props.configuratorId)
                .then(response => {
                    this.setState({...this.state, inquirySending: false, inquirySent: true, inquirySuccessful: true});
                })
                .catch(error => {
                    this.setState({...this.state, inquirySending: false, inquirySent: true, inquirySuccessful: false});
                });
        }
        else {
            this.setState({...this.state, inquiryMissingMandatoryFields: true});
        }
    }

    getConfiguratorHeadline() {
        const {resumePaths} = this.props;

        if (resumePaths) {
            switch (resumePaths.settings) {
                case 'ce180_grundeinstellungen':
                    return 'p_ce_headline_180';
                case 'ce200_grundeinstellungen':
                    return 'p_ce_headline_200';
                case 'ce230_grundeinstellungen':
                    return 'p_ce_headline_230';
                case 're_grundeinstellungen':
                    return 'Reno Elements'
                default:
                    return '';
            }
        }

        return '';
    }

    getCurrentHeight() {
        const {facts} = this.props;
        const {bom} = facts;

        let currentHeight = 0;

        if (bom && bom.categories) {
            bom.categories.forEach((value) => {
                if (value.id === '02-A' || value.id === '03-Z') {
                    if (value.entries) {
                        value.entries.forEach((entry) => {
                            if (entry && entry.quantity > 0 && entry.meta.inUse && entry.meta.isAccessory !== true) {
                                currentHeight += entry.meta.height;
                            }
                        });
                    }
                }
            });
        }

        return currentHeight;
    }

    render() {
        const {view, facts, parameterSet, resumePaths, configuratorId} = this.props;
        const {inquiryMissingMandatoryFields, inquirySending, inquirySent, inquirySuccessful} = this.state;
        const {bom} = facts;

        return (
            <AppInset>
                <div className="d-print-none">
                    <Link to={'/'} className={styles.backLink}>
                        <i className="fa far fa-angle-left"></i> <SnippetContent id="p_label_all_configurators" fallback="Alle Konfiguratoren" />
                    </Link>
                </div>
                <h1 className={styles.heading}>
                    <SnippetContent id={this.getConfiguratorHeadline()} fallback={this.getConfiguratorHeadline()} />
                </h1>
                <h2>
                    <SnippetContent id={'p_label_result'} fallback={view.name} />
                </h2>
                <p>{view.description}</p>

                <div className="d-print-none">
                    <Link className={styles.backButton} to={"../" + resumePaths.configuration}>
                        <i className="fa far fa-lg fa-angle-left"></i>
                    </Link>
                </div>

                {bom && bom.categories && (
                    <div className={styles.resultWrapper}>
                        <div className="row">
                            <div className="col-lg-4 hidden-sm">
                                <ResultImage configuratorId={parameterSet.externalid} />
                                {facts['p_profilhoehe'] && (
                                    <div className={styles.columnHeight}>
                                        <SnippetContent id={'p_label_result_column_height'} fallback="Säulenhöhe" /> {facts['p_profilhoehe']} mm
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-8">
                                <div className={styles.result}>
                                    <table className="table">
                                        <thead className="d-print-none">
                                            <tr>
                                                <th><SnippetContent id="p_label_position" fallback="Position" /></th>
                                                <th><SnippetContent id="p_label_quantity" fallback="Anzahl" /></th>
                                                <th colSpan="3"><SnippetContent id="p_label_product" fallback="Produkt" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.addPositions(bom.categories).sort((a, b) => a.pos - b.pos).map((category) => (
                                            <>
                                                {category.entries && (
                                                    <>
                                                        {category.entries.map((entry, index) => (
                                                            entry.quantity > 0 && ! entry.meta.isPlaceholder && (
                                                                entry.meta.isAccessory ? (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <SnippetContent id="p_re_pos_ACC" fallback="Zubehör optional" />
                                                                        </td>
                                                                        <td>{entry.quantity} x</td>
                                                                        <td></td>
                                                                        <td>
                                                                            <Product isAccessory={true} id={entry.id} />
                                                                        </td>
                                                                        <td>
                                                                            <ProductName isAccessory={true} id={entry.id} showSpecs={false} showNotice={true} />
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <SnippetContent id={'p_re_pos_' + category.id.substring(3)} fallback="" /><br />
                                                                            <SnippetContent id={'p_re_pos_' + entry.meta.orientation} fallback="" />
                                                                        </td>
                                                                        <td>{entry.quantity} x</td>
                                                                        <td>
                                                                            <Product id={entry.id} />
                                                                        </td>
                                                                        <td colSpan="2">
                                                                            <ProductName id={entry.id} meta={entry.meta} showSpecs={true} showNotice={true} />
                                                                            {configuratorId === 'city_elements' && category.id === '01-G' && (
                                                                                <span className={styles.productSpecsItem}>
                                                                                    <SnippetContent id="p_label_result_height" fallback="Höhe:" /> {facts['p_profilhoehe'] - this.getCurrentHeight()}  mm
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )
                                                        ))}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                        </tbody>
                                    </table>

                                    <p className={styles.techChangesNote}><SnippetContent id="p_label_tech_changes" fallback="Technische Änderungen vorbehalten. Abbildungen ähnlich." /></p>
                                </div>

                                <button className="btn btn-primary d-print-none" onClick={this.handlePrint}>
                                    <SnippetContent id="p_button_pdf" fallback="PDF drucken" />
                                </button>

                                <div className="mt-3 d-print-none">
                                    <h2>
                                        <SnippetContent id="p_label_inquire" fallback="Anfrage" />
                                    </h2>
                                    {inquiryMissingMandatoryFields && (
                                        <div className="alert alert-danger">
                                            <SnippetContent id="p_alert_mandatoryfields" fallback="Bitte füllen Sie alle Pflichtfelder aus." />
                                        </div>
                                    )}
                                    {inquirySent ? (
                                        <>
                                            {inquirySuccessful ? (
                                                <div className="alert alert-success">
                                                    <SnippetContent id="p_alert_inquiry_successful" fallback="Vielen Dank für Ihre Anfrage." />
                                                </div>
                                            ) : (
                                                <div className="alert alert-success">
                                                    <SnippetContent id="p_alert_inquiry_error" fallback="Es gab einen Fehler bei der Übermittlung Ihrer Daten." />
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div>
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="anfrageformular-name">
                                                    <SnippetContent id="p_form_name" fallback="Name" /> <span className="required">*</span>
                                                </label>
                                                <div className="input">
                                                    <input ref={node => this.inputName = node} required="required" className="form-control" id="anfrageformular-name" type="text" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="anfrageformular-email">
                                                    <SnippetContent id="p_form_email" fallback="E-Mail-Adresse" /> <span className="required">*</span>
                                                </label>
                                                <div className="input">
                                                    <input ref={node => this.inputEmail = node} required="required" className="form-control" id="anfrageformular-email" type="text" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="anfrageformular-company">
                                                    <SnippetContent id="p_form_company" fallback="Unternehmen" />
                                                </label>
                                                <div className="input">
                                                    <input ref={node => this.inputCompany = node} className="form-control" id="anfrageformular-company" type="text" />
                                                </div>
                                            </div>
                                            <div className="form-row mb-1">
                                                <div className="col-sm-4">
                                                    <label className="control-label" htmlFor="anfrageformular-postalCode">
                                                        <SnippetContent id="p_form_postalcode" fallback="PLZ" />
                                                    </label>
                                                    <div className="input">
                                                        <input ref={node => this.inputPostalCode = node} className="form-control" id="anfrageformular-postalCode" type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <label className="control-label" htmlFor="anfrageformular-locality">
                                                        <SnippetContent id="p_form_locality" fallback="Ort" />
                                                    </label>
                                                    <div className="input">
                                                        <input ref={node => this.inputLocality = node} className="form-control" id="anfrageformular-locality" type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="anfrageformular-message">
                                                    <SnippetContent id="p_form_message" fallback="Nachricht" /> <span className="required">*</span>
                                                </label>
                                                <div className="input">
                                                    <textarea ref={node => this.inputMessage = node} placeholder="" required="required" className="xxlarge form-control" id="anfrageformular-message"></textarea>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="input checkbox">
                                                    <div className="form-check">
                                                        <label className="add-on form-check-label" htmlFor="anfrageformular-datapolicy">
                                                            <input ref={node => this.inputAcceptConditions = node} required="required" className="add-on" id="anfrageformular-datapolicy" type="checkbox" value="1" />
                                                            <span>
                                                                <SnippetContent id="p_form_accept_conditions" fallback="" /> <span className="required">*</span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="actions">
                                                <nav className="form-navigation">
                                                    <div className="btn-toolbar" role="toolbar">
                                                        <div className="btn-group" role="group">
                                                            <span className="btn-group next submit">
                                                                {inquirySending ? (
                                                                    <button className="btn btn-primary" disabled>
                                                                        <SnippetContent id="p_button_submit" fallback="Absenden" /> …
                                                                    </button>
                                                                ) : (
                                                                    <button className="btn btn-primary" onClick={this.handleInquiry}>
                                                                        <SnippetContent id="p_button_submit" fallback="Absenden" />
                                                                    </button>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </AppInset>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultView);
